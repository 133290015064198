@import '~video-react/dist/video-react.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  font-family: 'Montserrat', sans-serif;

}

.logo {
  height: 4rem;
}

.logoFooter{
  height: 4rem;
  margin-left: auto;
  margin-right: -40px;
}




.footerstr {
  color: orange;
  font-size: 14px;
}

.footerli {
  font-size: 12px;
}

.garis {
  border-left: 1px solid black;
  height: 36px;
  margin-left: 10px;
  margin-right: 10px ;
}




.container-signUp{
  width: 531px;
  height: 699px;
  margin: auto;
  background-color: white;
  border-radius: 3px;
  padding-top: 39px;
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 49px;
}

.signUp-background{
  background-image: linear-gradient(0deg, rgba(245, 245, 245, 0.9), rgba(245, 245, 245, 0.9)), url(/src/components/assets/jumbotron.webp);
  background-size: cover;
  padding-top: 112px;
  padding-bottom: 112px;
}

.logIn-background{
  background-image: linear-gradient(0deg, rgba(245, 245, 245, 0.9), rgba(245, 245, 245, 0.9)), url(/src/components/assets/jumbotron.webp);
  background-size: cover;
  padding-top: 112px;
  padding-bottom: 112px;
}

.containerForm{
  width: auto;
  height: 42px;
  background-color: #FCFCFC;
  padding-left: 7px;
  padding-top: 7px;
  border-bottom: 1px solid #9F9F9F;
}

.formInput{
  width: auto;
  border-style: none;
  background-color: #fcfcfc;
}

.formInput:focus{
  outline: none !important;
}

.SignUp-button{
  text-align: right;
}

.forgotPass{
  text-align: right;
}

.loginButton{
  text-align: right;
}

.navbar{
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.034);
}

.container-logIn{
  width: 531px;
  height: 500px;
  margin: auto;
  background-color: white;
  border-radius: 3px;
  padding-top: 39px;
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 49px;
}

.accButton{
  background-color: #16213B !important;
}

.Judul:hover{
  text-decoration: none;
  color: rgb(80, 80, 80);
}

.Judul{
  color: black;
}

.containerSuggestion{
  background-color: white;
  width: 100%;
  /* height: 557px; */
  padding-top: 56px;
}



.containerText{
  width: 520px;
  text-align: justify;
}

@media only screen and (max-width: 1360px){
  .profileCardTeacher{
    width: 893px;
    height: 200px;
    border: 2px solid #e5e5e54b;
    padding-top: 28px;
    padding-left: 41px;
    margin-right: 45px;
    margin-left: 122px;
    margin-bottom: 45px;
  }

}

.footerSmall{
  padding-right: 410px;
}

.footerContainer{
  padding-top: 18px;
}

.jumbotronVid{
  width: 100%;
}



.backgroundTeacher {
  background-color: #F5F5F5;
  background-size: cover;
  padding-top: 112px;
  padding-bottom: 112px;
}

.detailBody{
  background-color: #F5F5F5;
  padding-bottom: 64px;
}



.jumbotronDetail{
  border-bottom: 1px solid rgba(0, 0, 0, 0.034);
  background-image:url(/src/components/assets/jumbotron.webp);
  height: 50vh;
  background-size: cover;
  width: 100%;
  margin-bottom: 0 !important;
  border-radius: 0px !important;
}

.insightCourse{
  text-align: center;
}







ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
  border: #F0A63E;
  
}

ul.header {
  background-color: rgba(240, 166, 62, 0.2);
  padding: 0;
  border: #F0A63E;
  text-align: center;

}
ul.header li a {
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-decoration: none;
  padding: 15px;
  display: inline-block;
}

.formCourse {
  border: 0;
  box-shadow: none;
}

.containerLesson {
  /* position: absolute; */
  width: auto;
  height: auto;
  /* White */
  background: #FFFFFF;
  /* Soft Grey */
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  /* Default */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}



.profileCardTeacher{
  background-color: white;
}

.tombolCourse{
  margin-left: auto;
  margin-right: 34px;
}

.dashboardTeacherRow{
  padding-top: 72px;
  padding-bottom: 180px;
  display:flex;
  
}

.coursesBoxUnderline{
  border-bottom: 1px solid #9999994b;
}

.coursesBoxRow h3{
  margin-left: 32px;
}

.coursesBoxRow{
  margin-bottom: 30px;
}



.keterangan{
  margin-bottom: 5px;
}

.enrolledStatus{
  font-size: 14px ;
}

.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.TeacherDashboard{
  background-color: #e5e5e5;
}

.Card {
  position: auto;
  width: 240px;
  height: 420px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.cardimg {
  width: 100%;
  height: 170px;
  background-size: cover;
}

.h4student {
  text-align: right;
  padding-right: 20px;
}

.notFound {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.imageTeacherDashboard {
  width: 275px;
  height: 185px;
  border-radius: 4px;
}

.imageProfile {
  width: 150px;
  height: 150px;
}
.footerLogo{
  text-align: right;
}

.dropdown-menu{
  margin-left: auto !important;
  text-align: center !important;
  align-self: flex-end !important;
}

.headerImageProfile{
  width: 120px;
}

.CardContent{
  margin-top: 25px;
}

.confirmationButton, 
.confirmationButton1{
  margin: 10px;
}

.headerProfileDropDown
.dropdown-toggle{
  margin-top: -10px;
  margin-bottom: -20px;
}

.headerImageProfileOutside{
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.dropdown-menu{
  margin-top: 25px !important;
}


/* ------------------------------------------------------------- */
/* SearchBar */
.search {
  border: 1px solid #16213B;
  width: 546px;
  box-sizing: border-box;
  border-radius: 5px;
}

.searchButton{
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border: none;
  border-radius: 5px;
  background-color: white;
}

.searchForm:focus, 
.searchButton:focus{
  box-shadow: none !important;
  outline: none !important;
  color: none !important;
  border: none !important;
}

.searchForm{
  border-style: none !important;
}

/* ------------------------------------------------------------- */
/* Detail Page */
@media only screen and (max-width: 1366px){

  .containerJumbotron{
    margin-left: 5vh !important;
  }

  .containerDescription{
    margin-left: -13vh;
  }

  .detailCard{
    margin-right: -7vh;
  }

  .relatedCourse{
    margin-left: -6vh !important;
  }

}

/* big Screen */
@media only screen and (min-width: 1366px){

  .containerJumbotron{
    margin-left: 20vh !important;
  }

  .containerDescription{
    margin-left: -18vh;
  }

  .detailCard{
    margin-right: -10vh;
  }

  .relatedCourse{
    margin-left: -9vh !important;
  }

}

.detailCardImage{
  width: 473.5px;
  height: 260px;
  margin: auto !important;
  border-radius: 3px;
  background-size: cover;
}

.videoCard{
  margin-left: -21px;
  margin-top: -21px;
}

.containerJumbotron{
  background-color: rgba(0, 0, 0, 0.425) !important;
  border-radius: 5px;
  width: 570px !important;
  margin-bottom: -10px !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.containerJumbotron p{
  font-size: 20px;
  margin-bottom: -5px;
}

.video-react-big-play-button{
  margin: auto;
  margin-left: 183px;
  margin-top: 100px;
}

.enrollSend{
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: white !important;
}

.detailCard{
  width: 474px;
  height: 625px;
  position: relative;
  margin-left: auto;
  margin-bottom: -324px;
  margin-top: -315px;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
}

.containerDescription{
  margin-top: 60px;
  margin-bottom: 72px;
  height: 250px;
}

.suggestionCard{
  margin-top: -62px;
}

.jumbotronDetail{
  background-size: cover !important;
}

.containerContent{
  width: 421px;
  height: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.034);
}

.containerContentButton{
  width: 415px;
  height: 54px;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  color: black !important;
  text-align: left;
}

.containerContentButton:focus{
  background-color: #EAF7FE !important;
}

.courseList{
  margin: auto !important;
  overflow-y: scroll;
  height: 120px;
}

.modalImg{
  width: 250px;
  border-radius: 5px;
}

.enrollModal .modal-dialog .modal-content{
  width: 750px;
  height: 250px;
  margin-top: 250px !important;
  margin-left: -80px;
}

.closeTextModal{
  margin: auto;
  text-align: right;
  margin-top: -10px;
  margin-right: -10px;
}

.closeTextModal button{
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgb(148, 148, 148);
  border-radius: 5px;
  color: rgb(148, 148, 148);
}

.footerModal{
  background-color: #FAFAFA;
  width: 748px;
  margin-left: -16px;
  height: 60px;
  margin-top: 15px;
  padding-top: 15px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.detailModalTiltle{
  margin-bottom: 0px;
  font-size: 18px;
}

.detailModalAuthors{
  font-size: 16px;
}

/* ------------------------------------------------------------- */
/* Jumbotron */
/* Top */
.judulHome{
  margin-top: 120px;
  margin-left: -15px;
}

.judulHome h1{
  font-size: 50px;
}

.jumbotronTop {
  background-image: linear-gradient(rgb(228, 225, 225), rgba(0, 0, 0, 0)), url(/src/components/assets/jumbotron.webp);
  background-size: cover;
  height: 200%;
  width: 100%;
  margin-bottom: 0 !important;
}

/* Bot */
.judulJumbotronBottom{
  margin-top: 70px;
}

.jumbotronBot {
  background-image: linear-gradient(rgba(241, 231, 231, 0.322), rgba(0, 0, 0, 0)), url(/src/components/assets/jumbotronBot.jpg);
  background-size: cover;
  height: 50vh;
  width: 100%;
  margin-bottom: 0 !important;
}

/* ------------------------------------------------------------- */
/* Home */
.paginationButton{
  margin-right: 15px;
}

.homeBody{
  bottom: 0;
  background-color: #E5E5E5;
  height: 10px;
  width: 100%;
  margin-top: 30px;
}
.dashboardTabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 120%;
    color: rgb(0, 0, 0);
  text-decoration: none;
  display: inline-block;
  float: none;
  border-bottom: 0px solid transparent;
}

/* ------------------------------------------------------------- */
/* Student Dashboard */
.coursesBox{
  width: 65vw;
  height: auto;
  border: 2px solid #e5e5e53a;
  margin-right: 20px;
  margin-left: 20px;
  background-color: white;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  border-radius: 5px;
}

.profileCardTeacher {
  border: 2px solid #e5e5e54b;
  text-align: center;
  width: 25vw;
  flex-direction: row;
  height: 25vw;
  margin-left: 15px;
  margin-bottom: 45px;
  padding-top: 51px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.coursesInside{
  height: 835px;
  overflow-y: scroll;
}

.imageCourse{
  padding-bottom: 20px;
}

.teacherButtons{
  text-align: right;
}

.allCourses{
  border-bottom: 1.5px solid #e5e5e54b;
}

/* Teacher Dashboard */
.coursesBoxTeacher{
  padding-top: 35px;
}

.imageTeacherDashboard{
  margin-bottom: 15px;
}

.searchFormNav {
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 15px;
}

/* ------------------------------------------------------------- */
/* Course Content */
.courseContentCard{
  width: 500px !important;
  height: 400px;
  margin-left: auto;
  margin-right: 100px;
  padding-top: 30px;
}

.courseListContent{
  overflow-y: scroll;
  height: 250px;
}

.contentCoursePlayer{
  margin-right: auto;
  margin-left: 100px;
}

.topCourse{
  margin-right: auto;
  margin-left: 115px;
}

.topCourseNavigation{
  margin-top: 50px;
  margin-bottom: 0px;
}

.courseTitle{
  margin-bottom: 20px;
}

.courseContentDescription{
  background-color: #E5E5E5;
  padding-top: 40px;
  padding-bottom: 80px;
  margin-top: 50px;
  margin-bottom: 100px;
}

/* ------------------------------------------------------------- */
/* Course Content */
.loadMoreClass button{
  margin: auto;
  margin-bottom: 20px;
}

.loadMoreButton{
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: 500;
}

.descCard{
  margin-left: 100px;
  margin-right: auto;
  width: 730px;
  height: 250px;
}

.nextCard{
  margin-right: 100px;
  margin-left: auto;
  width: 500px;
  display: flex;
  flex-direction: column;
}

.bottomBorderCourse{
  width: 100%;
  height: 10px;
  background-color: #E5E5E5;
}

/* ------------------------------------------------------------- */
/* Course Content */
.addNewLesson{
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: blue;
}

.addNewLesson:hover{
  text-decoration: underline;
}